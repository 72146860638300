import React from 'react';

const Education = () => (
  <section id="education" className="section">
    <h2>Education</h2>
    <div className="education-item">
      <div className="degree">Master of Science in Computer Science</div>
      <div className="institution-year">
        <span className="institution">Georgia Institute of Technology</span>
        <span className="year">2025 - 2027</span>
      </div>
    </div>

    <div className="education-item">
      <div className="degree">Master of Science in Software Engineering</div>
      <div className="institution-year">
        <span className="institution">Western Governors University</span>
        <span className="year">2025 - 2026</span>
      </div>
    </div>

    <div className="education-item">
      <div className="degree">Bachelor of Science in Computer Science</div>
      <div className="institution-year">
        <span className="institution">Western Governors University</span>
        <span className="year">Completed</span>
      </div>
    </div>
  </section>
);

export default Education;
